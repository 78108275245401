<template>
	<span id="shop" class="invisible relative -top-40 block"></span>
	<div class="mt-4 flex flex-col">
		<!-- Bundle builder button -->
		<NuxtLink
			to="/build-a-pack"
			no-prefetch
			:class="[
				'flex w-full items-center justify-center gap-2 rounded-md bg-primary py-1 text-lg font-semibold text-text',
				'transition duration-300 hover:scale-[102%] md:text-xl',
				'focus-visible:ring focus-visible:ring-primary focus-visible:ring-offset-2',
			]"
		>
			Build Your Own Bundle Pack <ArrowLongRightIcon class="h-6 stroke-2" />
		</NuxtLink>

		<h2 class="-mb-1 mt-4 md:-mb-3 md:mt-2">Monroe Dispensary Menu</h2>

		<!-- Shop -->
		<div class="grid lg:grid-cols-[20%_80%]">
			<ShopFilters v-if="width >= 1024" class="hidden lg:flex lg:flex-col" />

			<!-- This extra div around the archive was needed to stop it from streching to the height of the filters -->
			<div>
				<ShopActiveFilters class="my-2" />
				<!-- Products -->
				<div
					class="flex max-w-[calc(100vw-2.5rem)] flex-col justify-start"
					:class="{
						'min-h-[5000px] md:min-h-[2730px]': shouldSetMinHeight,
					}"
				>
					<!-- Live menu indicator -->
					<div class="my-2 flex w-full justify-end">
						<ShopRealTimeMenuIndicator />
					</div>
					<!-- Shop -->
					<div
						v-if="!ProductsStore.isLoading"
						class="grid gap-2 md:grid-cols-3 lg:grid-cols-4"
					>
						<ShopProduct
							v-for="product in ProductsStore.paginatedProducts"
							:key="product.id"
							:product="product"
						/>
					</div>

					<!-- No search results -->
					<div
						v-if="
							!ProductsStore.paginatedProducts.length && ProductsStore.products.length
						"
						class="my-auto flex flex-col items-center justify-center gap-4 py-48"
					>
						<LogomarkFrown class="mb-2 w-12 opacity-20 invert" />
						<p class="text-sm font-medium">
							These are not the results you are looking for...
						</p>
						<!-- this doesn't reset search -->
						<button
							class="text-sm text-zinc-600"
							@click="ProductsStore.resetFilterOptions"
						>
							Reset Filters
						</button>
					</div>

					<LoadingAnimation :loading="!ProductsStore.products.length" class="mx-auto" />

					<ClientOnly>
						<ShopPagination v-if="!ProductsStore.isLoading" class="mt-4" />
					</ClientOnly>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { useProductsStore } from '../stores/productsStore';
import { useWindowSize } from '@vueuse/core';
import ShopProduct from './shop/ShopProduct.vue';
import ShopFilters from './shop/ShopFilters.vue';
import ShopPagination from './shop/ShopPagination.vue';
import ShopActiveFilters from './shop/ShopActiveFilters.vue';
import ShopRealTimeMenuIndicator from './shop/ShopRealTimeMenuIndicator.vue';
import ArrowLongRightIcon from '@heroicons/vue/24/outline/ArrowLongRightIcon';
import LogomarkFrown from './icons/LogomarkFrown.vue';
import LoadingAnimation from './ui/LoadingAnimation.vue';

const ProductsStore = useProductsStore();
const { width } = useWindowSize();

const shouldSetMinHeight = computed(() => {
	if (
		ProductsStore.isLoading ||
		ProductsStore.filteredProducts.length >= ProductsStore.itemsPerPage
	) {
		return true;
	}
	return false;
});

onBeforeRouteLeave((to) => {
	if (!to.path.startsWith('/products')) {
		ProductsStore.resetFilterOptions();
	}
});
</script>

<template>
	<svg
		version="1.1"
		x="0px"
		y="0px"
		viewBox="0 0 153 158"
		enable-background="new 0 0 153 158"
		xml:space="preserve"
	>
		<path
			d="M11.9,41.3c-1.2-0.7-2.3-1.3-3.3-2.1c-1.7-1.5-2.6-3.4-3-5.6c-0.6-3.3-0.3-6.5,1.5-9.5c1.5-2.3,3.5-3.6,6.4-3.6
	c9.2,0.1,18.4,0,27.6,0c1.1,0,2.1-0.2,3-0.8c7.6-4.2,15.2-8.3,22.8-12.5c2.4-1.3,4.9-2,7.7-2c16.8,0,33.6,0,50.4,0
	c0.6,0,1.2,0,2.1,0c0-0.7,0-1.3,0-1.9c0-2.3,0.9-3.2,3.1-3.2c6.4,0,12.9,0,19.3,0c2.2,0,3.1,0.9,3.1,3.1c0,7.1,0,14.2,0,21.2
	c0,3.5,0,7.1,0,10.6c0,2.4-0.8,3.3-3.2,3.3c-6.4,0-12.8,0-19.2,0c-2.3,0-3.2-0.9-3.2-3.2c0-1.4,0-2.8,0-4.4c-0.9,0-1.7,0-2.5,0
	c-0.7,0-1.5,0-2.3,0c-1.4,7.6-5.8,11.8-13.5,12.8c0.1,0.6,0.2,1.1,0.4,1.7c3.2,10.9,6.4,21.8,9.6,32.6c0.6,1.9,0.9,3.9,0.9,5.9
	c0,23.2,0,46.5,0,69.7c0,0.4,0,0.8,0,1.3c-0.1,1.8-1,2.7-2.8,2.7c-0.4,0-0.8,0-1.1,0c-37.2,0-74.4,0-111.5,0c-0.5,0-0.9,0-1.4,0
	c-1.4-0.1-2.3-1-2.5-2.4c0-0.5,0-0.9,0-1.4c0-23.5,0-46.9,0-70.3c0-1.8,0.3-3.6,0.8-5.3c3.6-11.9,7.1-23.9,10.6-35.8
	C11.8,42,11.9,41.6,11.9,41.3z M99,46.7c-0.4,0.3-0.9,0.6-1.3,0.9c-3.4,2.6-6.8,5.3-10.1,7.9c-2.3,1.8-4.9,2.6-7.8,2.4
	C73,57.6,68,51.1,69.4,44.6c0.2-1.1,0.7-2.3,1-3.4c-17.6,0-35.1,0-52.7,0c-0.2,0.5-0.3,1-0.5,1.4c-3.7,12.3-7.3,24.7-10.9,37
	c-0.4,1.3-0.7,2.6-0.7,3.9c0,22.5,0,44.9,0,67.4c0,0.5,0.1,1,0.1,1.5c27.9,0,55.6,0,83.4,0c0-0.8,0-1.5,0-2.2c0-22.2,0-44.5,0-66.7
	c0-1.7,0.3-3.5,0.8-5.1c2.9-9.9,5.8-19.8,8.8-29.8C98.8,47.9,98.9,47.3,99,46.7L99,46.7L99,46.7z M54.4,20.4c0,0.1,0.1,0.2,0.1,0.2
	c0.5,0,1,0,1.5,0c11.7,0,23.3,0,35,0c1.4,0,2.6,0.3,3.1,1.7c0.5,1.4,0,2.4-1.1,3.4c-0.3,0.2-0.5,0.5-0.8,0.8
	C87.1,31.6,82.1,36.6,77,41.7c-0.3,0.3-0.6,0.6-0.9,0.9c-2.1,2.3-2.2,5.8-0.2,8.1c2,2.3,5.3,2.7,7.9,0.9c0.8-0.5,1.4-1.1,2.1-1.6
	c4.4-3.4,8.8-6.9,13.2-10.3c1.1-0.9,2.2-1.3,3.6-1.3c1.7,0.1,3.4,0.1,5.1,0c1.2-0.1,2.6-0.2,3.7-0.7c3.4-1.4,5-4.2,5.3-7.8
	c0.2-2.6,1.8-4.1,4.4-4.1c1.5,0,3,0,4.4,0c0.4,0,0.8-0.1,1.2-0.1c0-5,0-9.9,0-14.8c-0.5,0-0.9-0.1-1.2-0.1c-17,0-34,0-51,0
	c-1.8,0-3.4,0.5-5,1.3c-3.4,1.9-6.9,3.8-10.3,5.6C57.8,18.5,56.1,19.4,54.4,20.4z M94.3,148.7c2.3-2.3,4.2-4.3,6.3-6.2
	c0.9-0.8,1.2-1.7,1.2-2.9c0-17.1,0-34.2,0-51.3c0-0.5,0-1.1,0-1.6c0.1-1.6,1.1-2.6,2.6-2.6s2.6,1,2.7,2.7c0,0.5,0,0.9,0,1.4
	c0,17.2,0,34.3,0,51.5c0,1.1,0.2,1.9,1,2.7c1.9,1.7,3.6,3.6,5.4,5.4c0.3,0.3,0.6,0.5,1,0.9c0-0.4,0-0.6,0-0.9c0-21.3,0-42.7,0-64
	c0-1-0.2-2.1-0.5-3.1c-3-10.3-6-20.7-9.1-31c-0.1-0.4-0.3-0.7-0.5-1.2c-0.2,0.5-0.3,0.7-0.4,0.9c-3,10.2-6,20.3-9,30.5
	c-0.4,1.5-0.6,3-0.6,4.5c0,20.8,0,41.5,0,62.3C94.3,147.2,94.3,147.7,94.3,148.7z M85.1,26.1C85,26,85,25.9,84.9,25.8
	c-0.4,0-0.7,0-1.1,0c-23.1,0-46.2,0-69.2,0c-0.2,0-0.3,0-0.5,0c-1.3-0.1-2.1,0.5-2.6,1.6c-0.9,1.9-1,3.9-0.4,5.8
	c0.6,2,1.3,2.4,3.5,2.4c12,0,24,0,36,0c7.8,0,15.5,0,23.3,0c1.1,0,1.9-0.3,2.6-1.1c1.7-1.8,3.4-3.4,5.1-5.2
	C82.7,28.4,83.9,27.3,85.1,26.1z M132.5,5.6c0,9.2,0,18.3,0,27.5c5,0,9.9,0,14.8,0c0-9.2,0-18.3,0-27.5
	C142.3,5.6,137.5,5.6,132.5,5.6z M109.9,152.4c-1.8-1.9-3.8-3.8-5.6-5.6c-1.8,1.8-3.8,3.8-5.6,5.6
	C102.4,152.4,106.3,152.4,109.9,152.4z"
		/>
	</svg>
</template>

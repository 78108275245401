<template>
	<div
		v-if="ProductsStore.totalPages"
		class="flex flex-row flex-wrap items-center gap-2.5 text-center"
	>
		<IconsChevronLeft
			class="btn flex h-8 w-8 items-center justify-center rounded transition-all duration-300 ease-in-out"
			@click="changePage(ProductsStore.currentPage - 1)"
		/>
		<div
			v-for="n in ProductsStore.totalPages"
			:key="n"
			:class="{
				'cursor-default bg-cta text-white': n === ProductsStore.currentPage,
			}"
			class="btn flex h-8 w-8 items-center justify-center rounded transition-all duration-300 ease-in-out"
			@click="changePage(n)"
		>
			{{ n }}
		</div>
		<IconsChevronRight
			class="btn flex h-8 w-8 items-center justify-center rounded transition-all duration-300 ease-in-out"
			@click="changePage(ProductsStore.currentPage + 1)"
		/>
	</div>
</template>

<script setup lang="ts">
import { useProductsStore } from '../../stores/productsStore';
import smoothScrollTo from '../../utils/smoothScrollTo';

const ProductsStore = useProductsStore();

function changePage(newPage: number) {
	smoothScrollTo('#shop', 40);

	// Delay page change to avoid footer flashing from rerender
	setTimeout(() => {
		ProductsStore.currentPage = newPage;
	}, 500); // Roughly match scroll duration
}
</script>

<template>
	<div
		class="flex cursor-pointer items-center gap-1.5 text-sm text-cta"
		@mouseenter="showRealTimeMenuExplainer = true"
		@mouseleave="showRealTimeMenuExplainer = false"
	>
		<div class="h-2 w-2 animate-pulse rounded-full bg-cta"></div>
		Real-Time Menu
	</div>
	<Transition
		enter-active-class="transition-all duration-200 ease-in-out"
		leave-active-class="transition-all duration-200 ease-in-out"
		enter-from-class="opacity-0"
		leave-to-class="opacity-0"
	>
		<div
			v-show="showRealTimeMenuExplainer"
			class="absolute top-6 z-50 rounded bg-white p-4 text-sm shadow-md"
		>
			Our menu is updated in real-time.
		</div>
	</Transition>
</template>

<script setup lang="ts">
const showRealTimeMenuExplainer = ref(false);
</script>

<template>
	<svg
		version="1.1"
		x="0px"
		y="0px"
		viewBox="0 0 148 158"
		enable-background="new 0 0 148 158"
		xml:space="preserve"
	>
		<g>
			<g>
				<g>
					<path
						d="M57.5,77.7c0-1.4-1.1-2.5-2.5-2.5s-2.5,1.1-2.5,2.5c0,12,9.1,21.8,20.2,21.8c1.4,0,2.5-1.1,2.5-2.5s-1.1-2.5-2.5-2.5
				C64.3,94.6,57.5,87,57.5,77.7z"
					/>
					<path
						d="M103.3,74.6c1.4,0,2.5-1.1,2.5-2.5c0-8.9-5-16.6-12.2-20c-0.7-10.9-8.8-19.6-19-20.2c-0.1,0-0.2-0.1-0.3-0.1
				c-0.1,0-0.3,0-0.4,0s-0.2,0-0.4,0c-0.1,0-0.1,0.1-0.2,0.1c-11.2,0.5-20.2,8.9-20.7,19.3c-5.9,3.6-9.8,9.8-9.8,16.8
				c0,1.4,1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5c0-8.4,7.6-15.3,16.9-15.3c1.4,0,2.5-1.1,2.5-2.5s-1.1-2.5-2.5-2.5
				c-2.4,0-4.7,0.4-6.8,1.1c1.6-6.8,8.2-12,16.1-12.1c7.4,0.2,13.4,6.1,14.7,13.9c-1-0.2-1.9-0.3-3-0.3c-1.4,0-2.5,1.1-2.5,2.5
				s1.1,2.5,2.5,2.5c8.4,0,15.3,7.6,15.3,16.9C100.9,73.5,102,74.6,103.3,74.6z"
					/>
					<path
						d="M85,63c-1,1-1,2.5,0,3.5c6,6,5.5,16.2-1.1,22.7c-1,1-1,2.5,0,3.5c0.5,0.5,1.1,0.7,1.7,0.7s1.3-0.2,1.7-0.7
				c8.5-8.5,9-21.8,1.1-29.7C87.5,62,85.9,62,85,63z"
					/>
					<path
						d="M44.3,81.4c-0.4-1.8,0.1-3.8,1.4-5.4c0.9-1,0.8-2.6-0.3-3.5s-2.6-0.8-3.5,0.3c-2.3,2.8-3.2,6.3-2.5,9.7
				c0.7,3,2.6,5.5,5.4,7.1c0.4,0.2,0.8,0.3,1.2,0.3c0.9,0,1.7-0.4,2.1-1.2c0.7-1.2,0.3-2.7-0.9-3.4C45.7,84.4,44.6,83,44.3,81.4z"
					/>
					<path
						d="M104.2,78.2c-0.9-1-2.5-1.2-3.5-0.3c-1,0.9-1.2,2.4-0.3,3.5c1.3,1.6,1.8,3.5,1.4,5.4c-0.4,1.6-1.4,3-3,3.9
				c-1.2,0.7-1.6,2.2-0.9,3.4c0.5,0.8,1.3,1.2,2.1,1.2c0.4,0,0.8-0.1,1.2-0.3c2.8-1.6,4.7-4.1,5.4-7.1
				C107.5,84.5,106.6,81,104.2,78.2z"
					/>
					<path
						d="M64.7,69.6c1.3,0,2.4-1,2.5-2.3c0.2-2.1,1.2-3.8,2.8-4.8c1.4-0.9,3.1-1.1,4.9-0.7c1.3,0.4,2.7-0.4,3-1.7
				c0.4-1.3-0.4-2.7-1.7-3c-3.1-0.9-6.2-0.4-8.8,1.2c-2.9,1.8-4.8,5-5.1,8.6C62.2,68.3,63.2,69.4,64.7,69.6
				C64.5,69.6,64.6,69.6,64.7,69.6z"
					/>
					<path
						d="M89.5,97.2c-1,1.8-2.7,2.9-4.6,3.1c-1.7,0.2-3.3-0.4-4.7-1.5c-1-0.9-2.6-0.8-3.5,0.3c-0.9,1-0.8,2.6,0.3,3.5
				c0.5,0.4,1.1,0.7,1.7,1c-1.2,1.1-2.4,2.3-3.5,3.5v-0.7c0-1.4-1.1-2.5-2.5-2.5c-1.3,0-2.3,1-2.4,2.3c-1.4-1-2.8-1.7-3.6-2.1
				c-1.2-0.6-2.7-0.1-3.3,1.2c-0.6,1.2-0.1,2.7,1.2,3.3c2.7,1.3,5.5,3.5,5.7,4.4v6.6c0,1.4,1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5V115
				c2.4-3.5,6.8-8.6,9.7-9.7c0.2,0,0.4,0,0.6,0c3.4-0.4,6.5-2.4,8.3-5.6c0.7-1.2,0.3-2.7-0.9-3.4S90.1,96,89.5,97.2z"
					/>
					<path
						d="M64.7,101.5c-0.4-1.3-1.9-2-3.1-1.5c-1.9,0.7-4,0.4-5.5-0.6c-1.4-0.9-2.3-2.4-2.5-4.2c-0.2-1.3-1.3-2.3-2.8-2.1
				c-1.3,0.2-2.3,1.5-2.1,2.8c0.5,3.1,2.1,5.8,4.7,7.5c1.8,1.2,3.9,1.9,6.1,1.9c1.3,0,2.6-0.2,3.8-0.7
				C64.4,104.2,65.1,102.8,64.7,101.5z"
					/>
				</g>
				<g>
					<g>
						<path
							d="M66.1,144.4h-7.2l-1.5-8.8l-1.6,8.8h-7.2l-3.3-16.1H51l1.6,9.2l1.6-9.2h6.1l1.6,9.2l1.7-9.2h5.8L66.1,144.4z"
						/>
						<path
							d="M77.8,147.3l-1.7-2.7c-4-0.7-6.2-3.5-6.2-8.2c0-5.6,3.1-8.4,8.3-8.4c5.7,0,8.4,3.1,8.4,8.4c0,4.3-1.7,6.8-4.6,7.9
					l2.1,3.1h-6.3V147.3z M78.4,140c1.8,0,2.6-1.2,2.6-3.7c0-2.3-0.8-3.7-2.7-3.7c-1.8,0-2.6,1.5-2.6,3.7
					C75.7,138.7,76.6,140,78.4,140z"
						/>
						<path
							d="M88.2,139.2c0.7,0.4,2.6,1,4.7,1c1.3,0,1.7-0.2,1.7-0.7c0-0.6-0.5-0.6-1.9-1l-0.6-0.2c-3.3-0.9-4.1-2.9-4.1-5.5
					c0-3,2.4-5,6.4-5c2.4,0,4,0.3,5.3,0.8v4.5c-1.2-0.4-3.2-0.6-4.7-0.6c-1.2,0-1.7,0.2-1.7,0.7c0,0.8,1.1,0.6,3.2,1.2l0.7,0.2
					c2.8,0.9,3.4,2.9,3.4,5c0,3.3-2.6,5.1-7,5.1c-2.3,0-4.1-0.3-5.4-0.9V139.2z"
						/>
					</g>
					<g>
						<g>
							<path
								d="M86.9,149.3c-0.6,0-0.8,0.1-1.4,0.4c-1.1,0.6-5,3.9-12.3,3.9s-11.2-3.4-12.3-3.9c-0.6-0.3-0.8-0.4-1.4-0.4
						c-0.9,0-1.7,0.7-1.7,1.5c0,0.7,0.4,1,0.6,1.3c1.2,1.3,6.1,5.2,14.7,5.2s13.5-3.9,14.7-5.2c0.2-0.3,0.6-0.6,0.6-1.3
						C88.5,150,87.8,149.3,86.9,149.3L86.9,149.3z"
							/>
						</g>
					</g>
				</g>
				<path
					d="M73.4,0.9L0.9,53.7c-0.3,0.3-0.5,0.7-0.4,1.1l27.7,85.3c0.1,0.4,0.5,0.7,0.9,0.7h10.4c0.5,0,1-0.4,1-1v-5.3
			c0-0.5-0.4-1-1-1h-5.8L8.9,56.9L73.4,10c0.3-0.3,0.8-0.3,1.2,0l64,46.5c0.3,0.3,0.5,0.7,0.4,1.1l-24.7,75.9h-5.9c-0.5,0-1,0.4-1,1
			v5.3c0,0.5,0.4,1,1,1h10.5c0.4,0,0.8-0.3,0.9-0.7l27.7-85.3c0.1-0.4,0-0.9-0.4-1.1L74.6,0.9C74.2,0.7,73.8,0.7,73.4,0.9z"
				/>
			</g>
			<path
				d="M126.2,56.6l0.9,1.9c0.1,0.2,0.3,0.3,0.5,0.3l2.1,0.3c0.5,0.1,0.7,0.7,0.3,1.1l-1.5,1.5c-0.1,0.1-0.2,0.3-0.2,0.5l0.4,2.1
		c0.1,0.5-0.4,0.9-0.9,0.7l-1.9-1c-0.2-0.1-0.4-0.1-0.6,0l-1.9,1c-0.5,0.2-1-0.1-0.9-0.7l0.4-2.1c0-0.2,0-0.4-0.2-0.5l-1.5-1.5
		c-0.4-0.4-0.2-1,0.3-1.1l2.1-0.3c0.2,0,0.4-0.2,0.5-0.3l0.9-1.9C125.3,56.1,125.9,56.1,126.2,56.6z"
			/>
			<path
				d="M74.5,18.5l0.9,1.9c0.1,0.2,0.3,0.3,0.5,0.3L78,21c0.5,0.1,0.7,0.7,0.3,1.1l-1.5,1.5c-0.1,0.1-0.2,0.3-0.2,0.5l0.4,2.1
		c0.1,0.5-0.4,0.9-0.9,0.7l-1.9-1c-0.2-0.1-0.4-0.1-0.6,0l-1.9,1c-0.5,0.2-1-0.1-0.9-0.7l0.4-2.1c0-0.2,0-0.4-0.2-0.5l-1.5-1.5
		c-0.4-0.4-0.2-1,0.3-1.1l2.1-0.3c0.2,0,0.4-0.2,0.5-0.3l0.9-1.9C73.6,18,74.3,18,74.5,18.5z"
			/>
			<path
				d="M21.6,56.6l0.9,1.9c0.1,0.2,0.3,0.3,0.5,0.3l2.1,0.3c0.5,0.1,0.7,0.7,0.3,1.1L24,61.7c-0.1,0.1-0.2,0.3-0.2,0.5l0.4,2.1
		c0.1,0.5-0.4,0.9-0.9,0.7l-1.9-1c-0.2-0.1-0.4-0.1-0.6,0l-1.9,1c-0.5,0.2-1-0.1-0.9-0.7l0.4-2.1c0-0.2,0-0.4-0.2-0.5l-1.5-1.5
		c-0.4-0.4-0.2-1,0.3-1.1l2.1-0.3c0.2,0,0.4-0.2,0.5-0.3l0.9-1.9C20.8,56.1,21.4,56.1,21.6,56.6z"
			/>
		</g>
	</svg>
</template>

<template>
	<svg
		version="1.1"
		x="0px"
		y="0px"
		viewBox="0 0 271.5 173"
		enable-background="new 0 0 271.5 173"
		xml:space="preserve"
	>
		<g>
			<path
				d="M259.8,92.1H153.2c-6.5,0-11.7,5.3-11.7,11.7v57.5c0,6.5,5.3,11.7,11.7,11.7h106.6c6.5,0,11.7-5.3,11.7-11.7v-57.5
		C271.5,97.4,266.3,92.1,259.8,92.1z M266.5,161.3c0,3.7-3,6.7-6.7,6.7H153.2c-3.7,0-6.7-3-6.7-6.7v-57.5c0-3.7,3-6.7,6.7-6.7h106.6
		c3.7,0,6.7,3,6.7,6.7V161.3z"
			/>
			<path
				d="M222.3,106.6c-6,0-11.4,2-15.8,5.4c-4.5-3.5-10.1-5.4-15.8-5.4c-14.3,0-26,11.6-26,26s11.6,26,26,26
		c5.8,0,11.3-1.9,15.8-5.4c4.4,3.4,9.9,5.4,15.8,5.4c14.3,0,26-11.6,26-26S236.6,106.6,222.3,106.6z M169.7,132.5
		c0-11.6,9.4-21,21-21c4.3,0,8.6,1.4,12.1,3.9c-4,4.6-6.4,10.5-6.4,17.1c0,6.5,2.4,12.5,6.4,17.1c-3.5,2.5-7.7,3.9-12.1,3.9
		C179.1,153.5,169.7,144.1,169.7,132.5z M222.3,153.5c-11.6,0-21-9.4-21-21s9.4-21,21-21s21,9.4,21,21S233.9,153.5,222.3,153.5z"
			/>
			<path
				d="M118.3,91.2H11.7C5.3,91.2,0,96.4,0,102.9v57.5c0,6.5,5.3,11.7,11.7,11.7h106.6c6.5,0,11.7-5.3,11.7-11.7v-57.5
		C130,96.4,124.8,91.2,118.3,91.2z M125,160.4c0,3.7-3,6.7-6.7,6.7H11.7c-3.7,0-6.7-3-6.7-6.7v-57.5c0-3.7,3-6.7,6.7-6.7h106.6
		c3.7,0,6.7,3,6.7,6.7C125,102.9,125,160.4,125,160.4z"
			/>
			<path
				d="M118.7,0.8H12.1C5.7,0.8,0.4,6.1,0.4,12.6V70c0,6.5,5.3,11.7,11.7,11.7h106.6c6.5,0,11.7-5.3,11.7-11.7V12.6
		C130.4,6.1,125.2,0.8,118.7,0.8z M125.4,70c0,3.7-3,6.7-6.7,6.7H12.1c-3.7,0-6.7-3-6.7-6.7V12.6c0-3.7,3-6.7,6.7-6.7h106.6
		c3.7,0,6.7,3,6.7,6.7C125.4,12.6,125.4,70,125.4,70z"
			/>
			<path
				d="M115.4,32.8c-0.7,0.1-1.2,0.8-1,1.5c0.1,0.5,0.6,1,1.3,1c0.5,0,1-0.4,1.1-0.9v-0.1c0-0.1,0-0.3,0-0.4c0-0.1,0-0.2-0.1-0.2
		C116.5,33,115.9,32.7,115.4,32.8z M116.3,34.7c-0.3,0.3-0.6,0.4-1,0.3c-0.4-0.1-0.6-0.4-0.7-0.7v-0.1v-0.1c0-0.6,0.5-1.1,1.1-1
		c0.3,0,0.5,0.2,0.7,0.4C116.7,33.8,116.6,34.4,116.3,34.7z"
			/>
			<path
				d="M25.2,35.5c-1-1-2.2-1.7-3.5-2.1c-1-0.3-2-0.4-3.1-0.4c-1.3,0-2.5,0-3.8,0c-0.3,0-0.5,0-0.8,0c-0.1,0-0.1,0-0.1,0.1v0.1
		c0,2.6,0,5.3,0,7.9c0,2.6,0,5.3,0,7.9c0,0.2,0,0.2,0.2,0.2c1.5,0,3,0,4.4,0c0.5,0,1.1,0,1.6-0.1c0.6-0.1,1.2-0.2,1.7-0.4
		c1.4-0.5,2.6-1.3,3.6-2.5c1.1-1.3,1.7-2.9,1.9-4.6c0.1-0.9,0-1.7-0.2-2.5C26.8,37.7,26.2,36.5,25.2,35.5z M22.9,44.4
		c-0.7,0.9-1.6,1.5-2.7,1.7c-0.5,0.1-0.9,0.2-1.4,0.2s-1,0-1.5,0c-0.2,0-0.1,0-0.1-0.1c0-1.2,0-2.4,0-3.6c0-0.5,0-1.1,0-1.6
		c0-1.7,0-3.4,0-5.1v-0.1c0-0.1,0-0.1,0.1-0.1c0.5,0,1.1,0,1.6,0c0.7,0,1.4,0.2,2.1,0.4c1,0.4,1.8,1.2,2.3,2.1
		c0.4,0.7,0.6,1.4,0.7,2.2C24.2,41.9,23.8,43.2,22.9,44.4z"
			/>
			<path
				d="M110,42.3c0.3-0.1,0.6-0.2,0.9-0.3c1.2-0.5,2.1-1.4,2.5-2.7c0.3-1,0.3-2,0.1-3c-0.2-0.9-0.7-1.6-1.4-2.2
		c-0.6-0.4-1.2-0.7-1.9-0.9c-0.7-0.2-1.5-0.3-2.3-0.3c-1.5,0-3,0-4.5,0c-0.2,0-0.2,0-0.2,0.2c0,4.7,0,9.4,0,14.1c0,0.6,0,1.2,0,1.8
		c0,0.2,0,0.1,0.1,0.1c0.5,0,0.9,0,1.4,0s1,0,1.5,0c0.2,0,0.1,0,0.1-0.1c0,0,0,0,0-0.1c0-2,0-4.1,0-6.1v-0.1c0,0,0-0.1,0.1-0.1
		s0.1,0,0.2,0s0.2,0,0.2,0.1c1.4,2.1,2.8,4.2,4.2,6.3c0.1,0.1,0.1,0.1,0.2,0.1c1.2,0,2.4,0,3.6,0h0.1
		C113.3,46.8,111.6,44.6,110,42.3C109.9,42.3,109.9,42.3,110,42.3z M108.8,40.1c-0.4,0.2-0.8,0.2-1.2,0.2c-0.4,0-0.8,0-1.2,0
		c-0.1,0-0.1,0-0.1-0.1c0-0.8,0-1.6,0-2.3c0-0.8,0-1.5,0-2.3v-0.1c0-0.1,0-0.1,0.1-0.1c0.4,0,0.9,0,1.3,0s0.7,0.1,1.1,0.2
		c0.4,0.1,0.8,0.4,1,0.7c0.3,0.3,0.4,0.7,0.4,1c0.1,0.5,0,0.9-0.1,1.4C109.9,39.4,109.5,39.9,108.8,40.1z"
			/>
			<path
				d="M100.9,32.9c-2.9,0-5.8,0-8.6,0c-0.2,0-0.2,0-0.2,0.2c0,2.6,0,5.3,0,7.9c0,2.6,0,5.3,0,7.9c0,0.2,0,0.2,0.2,0.2
		c2.9,0,5.8,0,8.7,0h0.1c0,0,0.1,0,0.1-0.1c0,0,0,0,0-0.1c0-0.8,0-1.7,0-2.5c0-0.1,0-0.1-0.1-0.1c0,0,0,0-0.1,0c-1.8,0-3.6,0-5.5,0
		c-0.2,0-0.1,0-0.1-0.1c0,0,0,0,0-0.1c0-1.1,0-2.2,0-3.3c0-0.3,0-0.5,0-0.8c0-0.1,0-0.1,0.1-0.1h0.1c1.7,0,3.5,0,5.2,0h0.1
		c0,0,0.1,0,0.1-0.1v-0.1c0-0.8,0-1.6,0-2.4c0-0.2,0-0.2-0.2-0.2c-1.8,0-3.5,0-5.3,0c-0.2,0-0.1,0-0.1-0.1c0-0.5,0-1,0-1.6
		s0-1.2,0-1.8c0-0.1,0-0.1,0.1-0.1h0.1c0.9,0,1.7,0,2.6,0c1,0,1.9,0,2.9,0c0.1,0,0.1,0,0.1-0.1c0,0,0,0,0-0.1c0-0.8,0-1.6,0-2.4
		C101.1,32.9,101.1,32.9,100.9,32.9z"
			/>
			<path
				d="M43,40.9c-0.3-0.2-0.6-0.5-0.9-0.7c-0.6-0.4-1.3-0.6-1.9-0.9c-0.5-0.2-1-0.4-1.5-0.6c-0.3-0.1-0.6-0.3-0.9-0.5
		c-0.5-0.4-0.6-1-0.5-1.6c0.1-0.4,0.4-0.7,0.8-0.9c0.4-0.2,0.7-0.3,1.1-0.4c0.5-0.1,1,0,1.5,0.2s0.9,0.5,1.3,0.9
		c0.1,0.1,0.1,0.1,0.2,0.2l0.1-0.1c0.5-0.6,1-1.3,1.5-1.9l0.1-0.1v-0.1c0,0,0,0-0.1-0.1c-1.5-1.2-3.2-1.8-5.2-1.7
		c-0.7,0.1-1.4,0.2-2.1,0.6c-1.1,0.6-1.9,1.4-2.3,2.6c-0.2,0.7-0.3,1.5-0.2,2.3c0.1,0.9,0.6,1.7,1.3,2.2c0.5,0.4,1,0.7,1.6,0.9
		c0.6,0.3,1.1,0.4,1.7,0.7c0.5,0.2,1,0.4,1.5,0.6c0.8,0.4,1.2,1.3,1,2.2s-0.7,1.4-1.5,1.7c-0.4,0.2-0.9,0.2-1.4,0.1
		c-0.6-0.1-1.1-0.3-1.6-0.6c-0.5-0.4-0.9-0.8-1.3-1.4l-0.1-0.1l0,0c-0.6,0.6-1.3,1.2-1.9,1.9c-0.1,0-0.1,0.1,0,0.1
		c0.2,0.3,0.5,0.7,0.8,1c0.7,0.8,1.6,1.4,2.6,1.7c0.9,0.3,1.7,0.4,2.6,0.3c0.6,0,1.2-0.1,1.8-0.4c1.5-0.6,2.5-1.6,3.1-3.1
		c0.4-1,0.4-2.1,0.2-3.2C44,42.1,43.6,41.4,43,40.9z"
			/>
			<path
				d="M87.3,32.9c-0.1,0-0.1,0-0.2,0.1c-1.3,3.2-2.5,6.3-3.8,9.5c-0.2,0.4-0.3,0.8-0.5,1.2c0,0-0.1-0.1-0.1-0.2
		c-1.4-3.5-2.8-7-4.2-10.5c-0.1-0.1-0.1-0.1-0.2-0.1c-1.1,0-2.1,0-3.2,0H75c0,0.1,0,0.1,0.1,0.2c1.1,2.8,2.3,5.5,3.4,8.3
		c1.1,2.7,2.2,5.4,3.3,8c0.1,0.1,0.1,0.1,0.2,0.1c0.5,0,0.9,0,1.4,0c0.2,0,0.1,0,0.2-0.1c0.7-1.7,1.4-3.4,2.1-5
		c1.6-3.8,3.2-7.5,4.7-11.3c0-0.1,0.1-0.1,0.1-0.2h-0.1C89.5,32.9,88.4,32.9,87.3,32.9z"
			/>
			<path
				d="M57.8,44.8c-0.1,0.1-0.1,0.1-0.2,0.1c-0.2,0.2-0.5,0.4-0.8,0.6c-0.8,0.6-1.6,0.9-2.5,1c-0.6,0-1.2,0-1.8-0.2
		c-1.4-0.4-2.5-1.1-3.2-2.4c-0.4-0.8-0.7-1.6-0.7-2.5c-0.1-0.8,0-1.5,0.2-2.2c0.4-1.3,1.2-2.4,2.4-3.1c1-0.6,2-0.8,3.1-0.7
		c0.8,0.1,1.6,0.3,2.2,0.8c0.4,0.3,0.8,0.6,1.2,1l0.1,0.1l0,0c0-1.2,0-2.4,0-3.6c0-0.1,0-0.1-0.1-0.1c-1.1-0.6-2.3-0.9-3.5-0.9
		c-0.9,0-1.8,0.1-2.6,0.3c-3.4,0.9-6,3.9-6.2,7.6c-0.1,0.9,0,1.9,0.3,2.8c0.8,2.8,2.9,5,5.8,5.8c0.7,0.2,1.4,0.3,2.1,0.3
		c0.7,0,1.4,0,2.1-0.1c0.8-0.1,1.5-0.4,2.2-0.7c0.1,0,0.1-0.1,0.1-0.2c0-1.1,0-2.3,0-3.4C57.8,44.9,57.8,44.9,57.8,44.8z"
			/>
			<path
				d="M32,33.1L32,33.1c0-0.2,0-0.2-0.1-0.2c0,0,0,0-0.1,0c-0.9,0-1.9,0-2.8,0c-0.2,0-0.2,0-0.2,0.2c0,2.6,0,5.3,0,7.9
		c0,2.6,0,5.3,0,7.9V49c0,0.1,0,0.1,0.1,0.1H29c0.9,0,1.9,0,2.8,0c0.2,0,0.2,0,0.2-0.2C32,43.6,32,38.4,32,33.1z"
			/>
			<path
				d="M75.8,40.9c0-0.3,0-0.5-0.1-0.7c0-0.2-0.1-0.5-0.1-0.7c0-0.1,0-0.1-0.1-0.2c0-0.2-0.1-0.4-0.2-0.6v-0.1v-0.1
		c-0.1-0.3-0.3-0.7-0.4-1l-0.1-0.1c-0.1-0.2-0.2-0.4-0.3-0.6c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0-0.1
		c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.2-0.3-0.3-0.4-0.5c-0.1-0.1-0.2-0.2-0.2-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.2-0.2-0.4-0.3
		c-0.1-0.1-0.2-0.1-0.2-0.2l0,0l-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.2-0.2-0.3-0.2c-0.7-0.4-1.4-0.7-2.1-0.9
		c-0.4,0.1-0.7,0-0.9,0h-0.1c0,0,0,0-0.1,0h-0.1h-0.1h-0.1h-0.1c-0.1,0-0.1,0-0.2,0h-0.1h-0.1c-0.3,0-0.6,0-0.9,0h-0.1h-0.1
		c-0.1,0-0.1,0-0.2,0h-0.1h-0.1h-0.1h-0.1c-0.1,0-0.3,0-0.4,0.1c-1.9,0.4-3.5,1.3-4.8,2.8c-1,1.1-1.6,2.4-1.9,3.8
		c-0.1,0.5-0.2,1-0.2,1.5c0,0.3,0,0.6,0,0.8v0.1l0,0l0,0l0,0l0,0l0,0c0,0,0,0,0,0.1v0.1c0,0.1,0,0.1,0,0.2l0,0v0.1l0,0l0,0
		c0,0,0,0,0,0.1c0,0,0,0,0,0.1v0.1V43c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1l0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1v0.1
		c0,0.1,0.1,0.2,0.1,0.4c0.1,0.3,0.2,0.6,0.4,1v0.1c0,0,0,0,0,0.1c0.1,0.3,0.2,0.5,0.4,0.7c0.1,0.2,0.3,0.5,0.4,0.7
		c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.2,0.3,0.3,0.5,0.5c0,0,0,0.1,0.1,0.1c0,0.1,0.1,0.1,0.1,0.1s0,0.1,0.1,0.1
		c0,0.1,0.1,0.1,0.2,0.1c0.3,0.3,0.6,0.5,0.9,0.7c0,0,0,0,0.1,0.1c0.3,0.2,0.7,0.4,1,0.6c0,0,0,0,0.1,0c0.3,0.1,0.5,0.2,0.8,0.3
		c0.2,0.1,0.4,0.1,0.6,0.2l0,0c0.3,0.1,0.6,0.1,0.9,0.2c0.1,0,0.2,0,0.3,0.1c0.5,0,1.1,0,1.6,0c0,0,0,0,0.1,0c0.3,0,0.6-0.1,0.9-0.2
		c2.7-0.6,4.6-2.2,5.9-4.6c0.2-0.4,0.4-0.8,0.5-1.2v-0.1c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.2c0-0.1,0.1-0.3,0.1-0.4
		c0.1-0.3,0.1-0.6,0.1-0.9c0-0.1,0-0.2,0-0.2C75.9,41.7,75.9,41.3,75.8,40.9C75.9,40.9,75.8,40.9,75.8,40.9z"
			/>
			<path
				d="M115.8,34.2c0.2-0.1,0.3-0.2,0.2-0.5c0-0.2-0.2-0.3-0.4-0.4h-0.1c-0.1,0-0.3,0-0.4,0s-0.1,0-0.1,0.1c0,0.4,0,0.7,0,1.1
		c0,0.1,0,0.2,0,0.2c0.1,0.1,0.2,0,0.2,0c0.1,0,0.1,0,0.1-0.1s0-0.3,0-0.4c0.1,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.4
		c0,0,0,0.1,0.1,0.1s0.2,0,0.4,0C116.1,34.6,115.9,34.4,115.8,34.2L115.8,34.2z M115.6,33.9C115.6,34,115.6,34,115.6,33.9
		c-0.2,0-0.2,0-0.2-0.1s0-0.2,0-0.3c0.1,0,0.1,0,0.2,0s0.1,0.1,0.1,0.2C115.7,33.8,115.7,33.9,115.6,33.9z"
			/>
			<path
				d="M100.2,116.9c-2.3,0-3.3,0.6-4.2,2.7c-3.6,8.6-7.2,17.2-10.8,25.8c-0.1,0.3-0.2,0.5-0.4,0.9c2.6,0,5.1,0,7.5,0
		c0.2,0,0.6-0.3,0.7-0.5c0.4-1,0.8-2.1,1.2-3.2c0.2-0.5,0.4-0.7,0.9-0.7c1.2,0,2.5,0,3.7,0c1.8,0,3.6,0,5.4,0
		c0.2,1.1,0.5,2.2,0.7,3.3c0.1,0.8,0.4,1.2,1.3,1.1c1.1-0.1,2.1,0,3.2,0c0.9,0,1.8,0,2.8,0c-2.1-9.9-4.1-19.7-6.2-29.5
		C104.1,116.9,102.1,116.9,100.2,116.9z M96.7,135.8c1.3-3.5,2.6-7,3.8-10.5c0.1,0,0.1,0,0.2,0.1c0.7,3.5,1.4,6.9,2.2,10.5
		C100.8,135.8,98.8,135.8,96.7,135.8z"
			/>
			<path
				d="M53.6,116.9c-2.5,0-4.8,0-7.1,0c-0.7,0-1,0.2-1.2,0.8c-1.4,3.6-2.8,7.2-4.3,10.9c-1.1,2.7-2.1,5.3-3.2,8.2
		c-0.1-0.3-0.2-0.5-0.2-0.6c-1-5.3-2-10.6-3-15.9c-0.4-2.1-1.8-3.3-3.9-3.3c-4,0-7.9,0-11.9,0c-0.3,0-0.6,0.2-1,0.3
		c0,0.2,0,0.4,0,0.6c2.2,0.7,4.5,1.4,6.7,2.1c1.5,0.5,2.3,1.6,2.7,3.1c1.2,4.8,2.5,9.7,3.7,14.5c0.8,2.9,1.5,5.9,2.3,8.8
		c2.4,0,4.8,0,7.1,0c0.7,0,1.1-0.2,1.3-0.9c3.8-9.3,7.7-18.5,11.6-27.7C53.4,117.5,53.5,117.3,53.6,116.9z"
			/>
			<path
				d="M56.9,116.9c-2.1,9.8-4.2,19.6-6.3,29.5c0.4,0,0.7,0,1,0c1.8,0,3.6-0.1,5.4,0c1,0,1.3-0.2,1.6-1.2
		c1.6-7.7,3.3-15.4,4.9-23.1c0.4-1.7,0.7-3.4,1.1-5.2C62,116.9,59.5,116.9,56.9,116.9z"
			/>
			<path
				d="M81.6,130.4c-0.7-0.5-1.5-0.9-2.3-1.4c-1.2-0.7-2.4-1.3-3.6-2.1c-1.7-1.2-1.5-2.9,0.4-3.8c0.3-0.2,0.7-0.3,1-0.4
		c2.1-0.4,4.1-0.1,6.2,0.5c0.7,0.2,1.3,0.4,2,0.7c0.4-2.1,0.9-4.1,1.4-6.4c-2-0.4-3.9-0.9-5.8-1c-4.1-0.3-7.9,0.4-11.2,3.1
		c-3.4,2.8-5,8.9,0.3,12.6c1.2,0.8,2.5,1.5,3.7,2.2c0.8,0.5,1.7,0.9,2.5,1.6c1.6,1.3,1.3,3.1-0.5,4c-0.9,0.4-1.9,0.6-2.8,0.7
		c-2.8,0.2-5.5-0.5-8.1-1.8c-0.4,2-0.8,3.8-1.2,5.6c-0.2,0.7,0,1,0.7,1.2c3.8,1.2,7.7,1.6,11.7,0.8c2.6-0.5,5-1.6,6.9-3.6
		C86.5,139,85.9,133.4,81.6,130.4z"
			/>
			<path
				d="M153.1,80.9h106.6c6.5,0,11.7-5.3,11.7-11.7V11.7c0-6.5-5.3-11.7-11.7-11.7H153.1c-6.5,0-11.7,5.3-11.7,11.7v57.5
		C141.4,75.6,146.6,80.9,153.1,80.9z M146.4,11.7c0-3.7,3-6.7,6.7-6.7h106.6c3.7,0,6.7,3,6.7,6.7v57.5c0,3.7-3,6.7-6.7,6.7H153.1
		c-3.7,0-6.7-3-6.7-6.7L146.4,11.7L146.4,11.7z"
			/>
			<path
				d="M225.8,35.7c-3.1,0-6.2,0-9.3,0c0,0.5,0,0.9,0,1.4c0,0.4,0,0.9,0,1.4c2.7,0,5.3,0,8,0c0,1.1,0,2.1,0,3.2c-2.7,0-5.3,0-8,0
		c0,1.1,0,2.2,0,3.2c3,0,5.9,0,8.9,0c1.4-1.6,2.8-3.2,4.1-4.8C228.3,38.6,227,37.1,225.8,35.7z"
			/>
			<path
				d="M177.1,48.2c1.1,0,2.1,0,3.1,0c0.4,1.2,0.9,2.3,1.3,3.4c4.2,0,8.3,0,12.6,0c0-0.8,0-1.5,0-2.3c0.3,0.9,0.6,1.6,0.9,2.3
		c2.3,0,4.4,0,6.4,0c0.2-0.8,0.5-1.5,0.7-2.2h0.1c0,0.7,0,1.5,0,2.2c8.6,0,17.2,0,25.8,0c1-1.2,2.1-2.3,3.2-3.5c1,1.2,2,2.4,2.9,3.6
		c4.3,0,8.6,0,12.8,0c0,0,0.1,0,0.1-0.1c0,0,0-0.1,0.1-0.2c-2.7-3.6-5.4-7.3-8.1-11c0.2-0.4,0.4-0.7,0.6-1c1.1-1.3,2.2-2.5,3.2-3.8
		c0.5-0.6,1-1.1,1.4-1.7c0.9-1.3,2-2.4,2.9-3.7c0.3-0.4,0.6-0.8,1-1.2c-4.5,0-8.9,0-13.1,0c-1.1,1.2-2.1,2.3-3.2,3.5
		c-1-1.2-2-2.4-2.9-3.5c-9.4,0-18.6,0-27.9,0c-0.8,1.9-1.7,3.7-2.6,5.7c-0.9-2-1.7-3.9-2.6-5.7c-3.8,0-7.5,0-11.3,0
		c0,0.8,0,1.5,0,2.4c-0.5-0.9-0.9-1.7-1.2-2.4c-3.3,0-6.5,0-9.7,0c-0.5,1.2-1,2.3-1.5,3.5c-0.3,0.7-0.7,1.3-0.9,2
		c-0.4,1.1-0.8,2.2-1.3,3.2c-0.6,1.3-1.1,2.6-1.7,3.9c-0.4,0.8-0.7,1.8-1.1,2.7c-0.3,0.9-0.7,1.8-1.1,2.7c-0.6,1.5-1.2,3-1.8,4.5
		c3.7,0,7.4,0,11.2,0C176.2,50.6,176.7,49.4,177.1,48.2z M213.1,32.5c4.9,0,9.7,0,14.6,0c1.5,1.7,2.9,3.3,4.5,5.1
		c1.6-1.7,3.2-3.4,4.8-5.1c1.4,0,2.8,0,4.4,0c-2.2,2.7-4.7,5.2-6.9,8c0.3,0.4,0.7,0.8,1,1.3c0.6,1,1.6,1.7,2.2,2.7
		c0.5,0.8,1.2,1.4,1.8,2.1c0.5,0.6,1,1.2,1.5,1.9c-1.6,0-3.1,0-4.7,0c-1.3-1.7-2.7-3.5-4.1-5.3c-0.9,0.3-1,1.2-1.6,1.7
		s-1.1,1.2-1.6,1.8s-1.2,1-1.6,1.8c-4.7,0-9.5,0-14.3,0C213.1,43.1,213.1,37.8,213.1,32.5z M174.2,48.4c-1.3,0-2.7,0-4.1,0
		c0.8-1.8,1.6-3.6,2.3-5.5c0.5-1.2,1-2.5,1.5-3.6c0.6-1.3,1.1-2.7,1.7-3.9c0.4-0.8,0.7-1.6,1-2.5c0.1-0.1,0.2-0.3,0.3-0.4
		c1.8,0,3.6,0,5.4,0c2,4.9,4,9.8,6,14.9c0-5.1,0-10,0-14.9c2.1,0,4.2,0,6.2,0c0.1,0.2,0.1,0.5,0.3,0.7c0.8,1.8,1.7,3.7,2.5,5.5
		c0.7,1.5,1.3,3,2,4.6c1.6-3.7,3-7.2,4.5-10.8c2,0,4,0,6.1,0c0,5.3,0,10.6,0,16c-1.3,0-2.6,0-4,0c0-4,0-8,0-12
		c-0.4,0.9-0.9,1.8-1.3,2.7c-0.2,0.7-0.6,1.4-1,2.1c-0.8,1.8-1.6,3.6-2.4,5.4c-0.2,0.6-0.5,1.1-0.7,1.7c-1,0-2.1,0-3.2,0
		c-0.9-2-1.8-4-2.7-6.1c-0.9-2-1.8-4.1-2.7-6.4c0,4.3,0,8.4,0,12.5c-2.5,0-4.8,0-7.3,0c-0.8-1-0.8-2.3-1.4-3.4c-2.5,0-5,0-7.7,0
		C175.1,46.1,174.6,47.2,174.2,48.4z"
			/>
			<path
				d="M176.7,41.3c1.3,0.2,4.2,0.2,5.1,0c-0.8-2-1.6-4.1-2.5-6.2C178.3,37.1,177.7,39.3,176.7,41.3z"
			/>
		</g>
	</svg>
</template>

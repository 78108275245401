<template>
	<div v-if="!filtersAreDefault" class="flex flex-row flex-wrap justify-start gap-2 pt-2">
		<!-- Category filter -->
		<div
			v-if="ProductsStore.categoryUserSelection !== 'All'"
			class="flex w-fit cursor-pointer flex-row items-center gap-2 rounded border-2 border-cta px-2 py-1 text-sm font-semibold text-cta transition duration-300 hover:scale-[101%] hover:text-cta-hover md:px-4 md:text-base"
			@click="ProductsStore.categoryUserSelection = 'All'"
		>
			{{ ProductsStore.categoryUserSelection }}
			<XMarkIcon class="w-3 cursor-pointer stroke-[3] md:w-4" />
		</div>

		<!-- Lean filter -->
		<div v-if="!ProductsStore.leanUserSelection.includes('All')" class="flex gap-2">
			<div
				v-for="lean in ProductsStore.leanUserSelection"
				:key="lean"
				class="flex w-fit cursor-pointer flex-row items-center gap-2 rounded border-2 border-cta px-2 py-1 text-sm font-semibold text-cta transition duration-300 hover:scale-[101%] hover:text-cta-hover md:px-4 md:text-base"
				@click="removeLeanSelection(lean)"
			>
				{{ lean }}
				<XMarkIcon class="w-3 cursor-pointer stroke-[3] md:w-4" />
			</div>
		</div>

		<!-- Price tier filter -->
		<div
			v-if="ProductsStore.priceTierUserSelection !== 'All'"
			class="flex w-fit cursor-pointer flex-row items-center gap-2 rounded border-2 border-cta px-2 py-1 text-sm font-semibold text-cta transition duration-300 hover:scale-[101%] hover:text-cta-hover md:px-4 md:text-base"
			@click="ProductsStore.priceTierUserSelection = 'All'"
		>
			{{ ProductsStore.priceTierUserSelection }}
			<XMarkIcon class="w-3 cursor-pointer stroke-[3] md:w-4" />
		</div>

		<!-- Chapter filter -->
		<div
			v-if="ProductsStore.chapterUserSelection !== 'All'"
			class="flex w-fit cursor-pointer flex-row items-center gap-2 rounded border-2 border-cta px-2 py-1 text-sm font-semibold text-cta transition duration-300 hover:scale-[101%] hover:text-cta-hover md:px-4 md:text-base"
			@click="ProductsStore.chapterUserSelection = 'All'"
		>
			{{ ProductsStore.chapterUserSelection }}
			<XMarkIcon class="w-3 cursor-pointer stroke-[3] md:w-4" />
		</div>

		<!-- Dominate terpene filter -->
		<div
			v-if="ProductsStore.dominateTerpeneUserSelection !== 'All'"
			class="flex w-fit cursor-pointer flex-row items-center gap-2 rounded border-2 border-cta px-2 py-1 text-sm font-semibold text-cta transition duration-300 hover:scale-[101%] hover:text-cta-hover md:px-4 md:text-base"
			@click="ProductsStore.dominateTerpeneUserSelection = 'All'"
		>
			{{ ProductsStore.dominateTerpeneUserSelection }}
			<XMarkIcon class="w-3 cursor-pointer stroke-[3] md:w-4" />
		</div>

		<!-- Brand filter -->
		<div
			v-if="ProductsStore.brandUserSelection !== 'All'"
			class="flex w-fit cursor-pointer flex-row items-center gap-2 rounded border-2 border-cta px-2 py-1 text-sm font-semibold text-cta transition duration-300 hover:scale-[101%] hover:text-cta-hover md:px-4 md:text-base"
			@click="ProductsStore.brandUserSelection = 'All'"
		>
			{{ ProductsStore.brandUserSelection }}
			<XMarkIcon class="w-3 cursor-pointer stroke-[3] md:w-4" />
		</div>

		<button
			v-if="!filtersAreDefault"
			class="text-sm text-zinc-600"
			@click="ProductsStore.resetFilterOptions"
		>
			Reset Filters
		</button>
	</div>
</template>

<script setup lang="ts">
import { useProductsStore } from '../../stores/productsStore';
import XMarkIcon from '@heroicons/vue/24/outline/XMarkIcon';

const ProductsStore = useProductsStore();

function removeLeanSelection(lean: string) {
	if (ProductsStore.leanUserSelection.length === 1) {
		ProductsStore.leanUserSelection = ['All'];
		return;
	}

	ProductsStore.leanUserSelection.splice(ProductsStore.leanUserSelection.indexOf(lean), 1);
}

const filtersAreDefault = computed(() => {
	return (
		ProductsStore.brandUserSelection === 'All' &&
		ProductsStore.categoryUserSelection === 'All' &&
		ProductsStore.chapterUserSelection === 'All' &&
		ProductsStore.leanUserSelection.length === 1 &&
		ProductsStore.leanUserSelection[0] === 'All' &&
		ProductsStore.priceTierUserSelection === 'All' &&
		ProductsStore.dominateTerpeneUserSelection === 'All'
	);
});
</script>

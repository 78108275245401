<template>
	<section class="relative flex w-full items-start lg:ml-0">
		<div
			class="no-scrollbar flex w-full flex-row gap-2 overflow-scroll py-4 lg:grid lg:grid-cols-5"
		>
			<div
				v-for="item in content"
				:key="item.category"
				:class="[
					'flex h-12 flex-[0_0_120px] cursor-pointer items-center rounded-lg text-text',
					'bg-grey-very-light',
					'md:h-20 md:flex-[0_0_240px] lg:h-24 lg:text-base',
					'overflow-hidden',
				]"
				@click="selectCategory(item.category)"
			>
				<img :src="item.image" :alt="item.alt" loading="lazy" class="mix-blend-darken" />
				<p
					class="absolute right-2 whitespace-pre text-right text-xs font-semibold text-text lg:text-xl lg:leading-6"
				>
					{{ item.text }}
				</p>
			</div>
		</div>
	</section>
</template>

<script setup lang="ts">
import smoothScrollTo from '~/utils/smoothScrollTo';
import { useProductsStore } from '~/stores/productsStore';

const ProductsStore = useProductsStore();

const content = [
	{
		image: 'https://assets.weedys.com/storage/v1/object/public/Images/category-bucket-flower.jpg',
		text: `Shop\nFlower`,
		category: 'Flower',
		alt: 'Shop flower category',
	},
	{
		image: 'https://assets.weedys.com/storage/v1/object/public/Images/category-bucket-packs.jpg',
		text: `Shop\nPacks`,
		category: 'Bundle Pack',
		alt: 'Shop bundle pack category',
	},
	{
		image: 'https://assets.weedys.com/storage/v1/object/public/Images/category-buckets-concentrate.jpg',
		text: `Shop\nConcentrate`,
		category: 'Concentrate',
		alt: 'Shop concentrate category',
	},
	{
		image: 'https://assets.weedys.com/storage/v1/object/public/Images/category-bucket-preroll.jpg',
		text: `Shop\nPrerolls`,
		category: 'Preroll',
		alt: 'Shop preroll category',
	},
	{
		image: 'https://assets.weedys.com/storage/v1/object/public/Images/category-bucket-apparel.jpg',
		text: `Shop\nApparel`,
		category: 'Clothing',
		alt: 'Shop clothing category',
	},
];

function selectCategory(category: string) {
	smoothScrollTo('#shop');
	ProductsStore.categoryUserSelection = category;
}
</script>
